.container {
    min-height: 100vh;
  }
  
  .logo {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 120px;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  