.pagination {
  // justify-content: center;
  gap: 5px;
  margin-top: 20px;
}

.pageNumber {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.pageActive {
  color: red;
}
